import styled, { keyframes } from "styled-components"

const floatingAnimation = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`

export const Icon = styled.span`
  position: absolute;
  left: calc(50% - 20px);
  top: 70%;
  transform: translate(-50%, -50%);
  font-size: 70px;

  animation: ${floatingAnimation} 6s ease-in-out infinite;

  @media screen and (min-width: 1024px) {
    top: 55%;
  }
`
