import React from "react"
import Section from "~/components/Section"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Masonry from "react-masonry-css"
import IconArrowDown from "~/components/IconArrowDown"

const BbyPage = () => {
  const breakpointColumnsObj = {
    default: 2,
    768: 1,
  }

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "bby" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section sm>
      <h1>Hey bae,</h1>

      <p>
        I'm here just to tell not only how much I love you, but also how you
        inspire me and anyone who's near you.
      </p>

      <p>
        You are <strong>strong</strong>. <br />
        You are <strong>brave</strong>.
      </p>

      <p>
        This is a little gift I came up with. <br />
        That's you in the <i>meta-verse</i>, heh.
      </p>

      <IconArrowDown />

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data.allFile.edges.map(({ node }, index) => (
          <Img key={index} fluid={node.childImageSharp.fluid} />
        ))}
      </Masonry>

      <br />

      <p>
        We all are really <strong>proud</strong> of you.
      </p>

      <p>
        Seja <strong>esperança</strong>.
      </p>
    </Section>
  )
}

export default BbyPage
